import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { lazy } from 'react';
import { StyledBar } from '../../utils/style/Atoms'
import colors from '../../utils/style/colors'
import { useTheme } from '../../utils/hooks'
import DarkLogo from '../../assets/FooterDarkLogo2.webp'
import LightLogo from '../../assets/FooterLightLogo1.webp'
import FacebookLogo from '../../assets/FacebookLogo1.webp'
import LinkedinLogo from '../../assets/LinkedinLogo1.webp'
import OPSLogo from '../../assets/OPSLogo1.webp'
import HoodspotLogo from '../../assets/HoodspotLogo1.webp'
import MaltLogo from '../../assets/MaltLogo1.webp'
import CodeurLogo from '../../assets/CodeurLogo1.webp'
import FooterBackground from '../../../src/assets/background.webp'

const ContactForm = lazy(() =>
  import(
    /* webpackChunkName: "contactForm" */ /* webpackPreload: true */ '../ContactForm/index'
  )
);

 
const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px 0;
    margin: auto;
    max-width: 2400px;
    transition: all 200ms;
    background-color: white;
    @media screen and (min-width: 2399px) {
        box-shadow: rgb(226, 227, 233) 2px -2px 6px;
    }
`

const DataContainer = styled.section`
    display: flex;
    flex-direction: row;
    flew-wrap: wrap;
    justify-content: center;
    gap: 50px;
    width: 100%;
    align-items: center;
    transition: all 200ms;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0px;
    }
    @media screen and (min-width: 481px) {
        background: linear-gradient(70deg, rgba(174,137,100,0.1) 0%, rgba(41,39,46,1) 60%), url(${FooterBackground}) left center no-repeat;
    }
    @media screen and (max-width: 480px) {
        background-color: ${({ theme }) => (theme === 'light' ? colors.celadon : colors.backgroundDark)};
        background-image: none;
    }
`

const EntityContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 40%;
    min-width: 400px;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
    @media screen and (max-width: 488px) {
        width: 100%;
        min-width: 0;
    }
`

const FooterLogo = styled.img`
    width: 90px;
    height: 48px;
    z-index: 2;
    margin: 20px 0 0 0;
    @media screen and (max-width: 488px) {
        margin-bottom: 20px;
    }
`

const Entity = styled.article`
    text-align: center;
    color: ${colors.white};
    transition: all 200ms;
    padding: 10px 0;
    @media screen and (min-width: 481px) and (max-width: 768px) {
        padding: 15px 0;
    }
    @media screen and (min-width: 769px) {
        padding: 40px 60px;
    }
    ${StyledBar} {
        margin: 40px auto;
        @media screen and (max-width: 980px) {
            margin: 30px auto;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    & > h3 {
        @media screen and (max-width: 480px) {
            font-size: 20px;
        }
    }
    & > p:first-of-type {
        margin-top: 30px;
        @media screen and (max-width: 769px) {
            margin-top: 20px;
        }
        @media screen and (max-width: 480px) {
            margin-top: 10px;
            margin-bottom: 0px;
        }
    }
    & > a {
        font-size: 18px;
        color: ${colors.white};
        display: block;
        font-weight: bold;
        margin-top: 30px;
        transition: all 200ms;
        &:hover {
            transform: scale(1.02);
        }
        & + a {
            font-weight: bold;
            color: ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary)};
        }
        @media screen and (max-width: 769px) {
            margin-top: 20px;
        }
        @media screen and (max-width: 480px) {
            margin-top: 10px;
        }
    }
`

const FormContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 30%;
    min-width: 360px;
    transition: all 200ms;
    & legend:first-of-type {
        margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
        min-width: 280px;
    }
`

const LegalContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    @media screen and (min-width: 810px) {
        flex-direction: row;
    }
    @media screen and (max-width: 480px) {
        padding: 10px 0;
        margin-bottom: 10px;
        margin-top: 10px;
    }
`

const SocialContainer = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 20px 0;
    gap: 20px;
    @media screen and (min-width: 810px) {
        flex-direction: row;
    }
    @media screen and (max-width: 480px) {
        padding: 10px 0;
        gap: 15px;
        margin-top: 30px;
    }
    @media screen and (max-width: 410px) {
        max-width: 70%;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 25px;
    }
    @media screen and (max-width: 310px) {
        max-width: 80%;
        gap: 15px;
    }
`

const SocialLink = styled.a`
    display: flex;
    flex-direction: column;
    margin: 0px;
`

const SocialLogo = styled.img`
    width: 36px;
    height: 36px;
    z-index: 2;
    margin: 5px 10px;
`

const FooterLink = styled(Link)`
    color: ${colors.black};
    padding: 0px 35px;
    position: initial;
    text-align: center;
    margin: 10px auto;
    transition: all 200ms;
    text-decoration: none;
    backface-visibility: hidden;
        @media screen and (min-width: 810px) {
        position: relative;
        right: 20px;
        margin: auto;
    }
    &:hover {
        transform: translateZ(0) scale(1.08);
    }
    @media screen and (max-width: 480px) {
        font-size: 15px;
    }
`

 
const NightModeButton = styled.button`
    background: ${({ theme }) => (theme === 'light' ? `linear-gradient(160deg, ${colors.orange} 0%,Ivory 70%)` : `linear-gradient(20deg, ${colors.primary} 0%,Ivory 70%)`)};
    cursor: pointer;
    color: black;
    margin-top: 10px;
    padding: 5px 20px;
    border-radius: 2px;
    border-width: 0px;
    min-width: 200px;
    max-width: 250px;
    transition: all 200ms;
    box-shadow: 2px 2px 6px ${colors.secondary};
    &:hover {
        box-shadow: 4px 4px 6px ${colors.secondary};
        transform: scale(1.04);
    }
`

const Copyright = styled.section`
    color: ${colors.black};
    margin-top: 10px;
    text-align: center;
    transition: all 200ms;
    @media screen and (max-width: 480px) {
        margin-top: 10px;
        font-size: 14px;
    }
`
 
function Footer() {
    const { toggleTheme, theme } = useTheme()

    const contactInformations = [
        {
            id: 1,
            name: 'Becom\'in',
            logo: 'https://becom-in.fr/api/images/logo.png',
            adress: '6 ruelle du seigle',
            CP: '14930',
            city: 'MALTOT',
            email: 'contact@becom-in.fr',
            phone: '06 58 19 22 52',           
        }
    ]

    const MailTo = ({ email, subject = '', body = '', title }) => {
        let params = subject || body ? '?' : ''
        if (subject) params += `subject=${encodeURIComponent(subject)}`
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`
    
        return (
            <a href={`mailto:${email}${params}`} title={`${title}`} style={{ textDecoration: 'none' }}>
                {email}
            </a>
        )
    }

    const PhoneTo = ({ phoneNumber, className, title }) => {
        let formattedPhoneNumber = phoneNumber.replace(/ /g,"");
        return (
            <a href={`tel:${formattedPhoneNumber}`} className={`${className}`} title={`${title}`} style={{ textDecoration: 'none' }}>
                {phoneNumber}
            </a>
        )
    }

    return (
        <FooterContainer id='contact'>
            
            <DataContainer theme={theme}>
                <EntityContainer >
                    {contactInformations?.map((contact) => (
                        <Entity theme={theme} key={`card-${contact.id}`}>
                            <FooterLogo src={theme === 'light' ? LightLogo : DarkLogo} alt="Logo Becom'In Footer" title="Logo Becom'in" />
                            <StyledBar theme={theme}></StyledBar>
                            <h3>{contact.name.toUpperCase()}</h3>
                            <p>{contact.adress}</p>
                            <p>{contact.CP} {contact.city}</p>
                            <PhoneTo className='phone-number' phoneNumber={contact.phone} title={`Téléphoner à ${contact.name}`} theme={theme}></PhoneTo>
                            <MailTo email={contact.email} subject="Demande de renseignements" body="Saisissez votre message ici" title={`Envoyer un mail rapide à ${contact.name}`}></MailTo>
                        </Entity>
                    ))}
                </EntityContainer>
                <FormContainer>
                    <ContactForm>
                    </ContactForm>
                </FormContainer>
            </DataContainer>
            
            <LegalContainer>
                <FooterLink to='/conditions-generales-de-vente' href='#home' title="Découvrir nos conditions générales de vente" >Conditions générales de vente</FooterLink>
                <FooterLink to='/mentions-legales' href='#home' title="Découvrir nos mentions légales">Mentions légales</FooterLink>
                <FooterLink to='/politique-de-confidentialite' href='#home' title="Découvrir notre politique de confidentialité">Politique de confidentialité</FooterLink>
            </LegalContainer>
            
            <NightModeButton theme={theme} onClick={() => toggleTheme()} title='Je change de thème'>
                Changer de thème
            </NightModeButton>

            <SocialContainer>
                <SocialLink href="https://www.facebook.com/BecomInCaenDeveloppementWebMobile" title="Vers page Facebook Becom'in" target="_blank">
                    <SocialLogo src={FacebookLogo} alt="Page Facebook Becom'in" title="Rejoindre Becom'in sur Facebook" /> 
                </SocialLink> 
                <SocialLink href="https://www.linkedin.com/company/becom-in-caen" title="Vers page Linkedin Becom'in" target="_blank">
                    <SocialLogo src={LinkedinLogo} alt="Page Linkedin Becom'in" title="Rejoindre Becom'in sur Linkedin" />
                </SocialLink>
                <SocialLink href="https://www.officeprosecretariat.com" title="Vers page Office Pro Secretariat" target="_blank">
                    <SocialLogo src={OPSLogo} alt="Partenaire Becom'in - Office Pro Secretariat" title="Rejoindre OPS - Partenaire Officiel" />
                </SocialLink>  
                <SocialLink href="https://hoodspot.fr/programmeur-informatique/becom-in-88250063000018" title="Vers Becom'in sur Hoodspot" target="_blank">
                    <SocialLogo src={HoodspotLogo} alt="Becom'in - L'annuaire Hoodspot" title="Consultez Becom'in sur Hoodspot" />
                </SocialLink>
                <SocialLink href="https://www.malt.fr/profile/benoitvincent2" title="Vers Becom'in sur Malt" target="_blank">
                    <SocialLogo src={MaltLogo} alt="Becom'in - Profil Malt" title="Contactez Becom'in sur Malt" />
                </SocialLink>
                <SocialLink href="https://www.codeur.com/-becom-in" title="Profil de Becom'in sur Codeur" target="_blank">
                    <SocialLogo src={CodeurLogo} alt="Becom'in - Profil Codeur" title="Consultez le profil de Becom'in sur Codeur" />
                </SocialLink>  
            </SocialContainer>
        
            {contactInformations?.map((contact) => (
                <Copyright key={`copyright-${contact.id}`} > © {contact.name.toUpperCase()} - Tous droits réservés - 2023</Copyright>
            ))}
        
        </FooterContainer>
    )
}
export default Footer